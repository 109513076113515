import axios from 'axios'
import { Message } from 'element-ui'
// import router from './router'

// 创建实例
const axiosInstance = axios.create({
  // 请求域名
  baseURL: process.env.VUE_APP_API_URL,
  // 设置超时时间
  timeout: 1000 * 30,
  // 请求头
  headers: {
    'Content-Type': 'application/json'
  }
})

// 请求拦截器
// axiosInstance.interceptors.request.use(
//   config => {
//     const token = 'xxx' // 登录后生成用于识别用户身份，项目不需要直接去掉
//     config.headers['Authorization'] = token || ''
//     return config
//   },
//   error => {
//     console.error('error: ', error)
//     Promise.reject(error)
//   }
// )

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data
    } else {//数据异常处理
      Message.info(JSON.stringify(response.status))
      return response.data
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance

