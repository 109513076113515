import serve from '../utils/request.js'

// 新闻类型
export const newsTypeList = (params) => serve.get('/qihang/current/newsTypeList', { params })

// 新闻动态列表
export const newsInformationList = (params) => serve.get('/qihang/current/newsInformationList', { params })

// 招聘信息列表
export const recruitList = (params) => serve.get('/qihang/current/recruitList', { params })

// 大数据应用
export const bigDataList = (params) => serve.get('/qihang/current/bigDataList', { params })

// 发展历程列表
export const aboutUsList = (params) => serve.get('/qihang/current/aboutUsList', { params })

// 新增简历
export const add = (data) => serve.post('/qihang/current/add', data)